:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
    --transitionDuration: 0.3s;
    --boxShadow: 2px 0 10px rgba(0, 0, 0, 0.1);
    --borderRadius: 0.5rem;
}

.sidebar {
    display: flex;
    height: 100vh;
}

.blog-container {
    flex: 1;
    padding: 2rem;
    overflow-y: auto;
}

.blog-title {
    color: var(--PrimaryColor);
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
}

.add-blog-button {
    background-color: var(--PrimaryColor);
    color: var(--whiteColor);
    padding: 0.75rem 1rem;
    border: none;
    border-radius: var(--borderRadius);
    cursor: pointer;
    transition: background-color var(--transitionDuration);
    font-weight: bold;
    margin-bottom: 2rem;
}
.popup-content::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
}

/* Hide scrollbar for Firefox */
.popup-content {
    scrollbar-width: none; /* Hide scrollbar */
}
.add-blog-button:hover {
    background-color: var(--HoverColor);
}


.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background-color: var(--whiteColor);
    padding: 2rem;
    border-radius: var(--borderRadius);
  
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-title {
    font-size: 1.5rem;
    color: var(--PrimaryColor);
    margin-bottom: 1.5rem;
    text-align: center;
    font-weight: bold;
}

.blog-form {
    display: grid;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.form-group label {
    color: var(--textColor);
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 0.9rem;
}

.form-group input,
.form-group textarea {
    padding: 0.75rem;
    border: 1px solid var(--greyText);
    border-radius: var(--borderRadius);
    background-color: var(--inputColor);
    transition: border-color var(--transitionDuration);
    font-size: 0.9rem;
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: var(--PrimaryColor);
    outline: none;
    box-shadow: 0 0 0 2px rgba(var(--PrimaryColor), 0.1);
}

.form-group textarea {
    min-height: 100px;
    resize: vertical;
}

.form-group input[type="file"] {
    padding: 0.5rem;
    font-size: 0.8rem;
}

.uploaded-image {
    max-width: 100%;
    height: auto;
    margin-top: 1rem;
    border-radius: var(--borderRadius);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
}

.form-actions button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: var(--borderRadius);
    cursor: pointer;
    transition: background-color var(--transitionDuration), transform 0.1s;
    font-weight: bold;
    font-size: 0.9rem;
    flex: 1;
    margin: 0 0.5rem;
}

.form-actions button[type="submit"] {
    background-color: var(--PrimaryColor);
    color: var(--whiteColor);
}

.form-actions button[type="submit"]:hover {
    background-color: var(--HoverColor);
    transform: translateY(-1px);
}

.form-actions button[type="button"] {
    background-color: var(--greyBg);
    color: var(--textColor);
}

.form-actions button[type="button"]:hover {
    background-color: var(--greyText);
    transform: translateY(-1px);
}

.blog-table-wrapper {
    background-color: var(--whiteColor);
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
    padding: 2rem;
    margin-top: 2rem;
}

.blog-table-title {
    color: var(--PrimaryColor);
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.blog-table {
    width: 100%;
    border-collapse: collapse;
}

.blog-table th,
.blog-table td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid var(--greyText);
}

.blog-table th {
    background-color: var(--greyBg);
    color: var(--blackColor);
}

.blog-table-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: var(--borderRadius);
}

.actions-btn {
    display: flex;
    gap: 10px;
}

.action-button {
    border: none;
    border-radius: var(--borderRadius);
    cursor: pointer;
    font-weight: bold;
    transition: background-color var(--transitionDuration);
    width: 65px;
    height: 40px;
}

.action-button.edit {
    background-color: var(--PrimaryColor);
    color: var(--whiteColor);
}

.action-button.edit:hover {
    background-color: var(--HoverColor);
}

.action-button.delete {
    background-color: #ff4d4d;
    color: var(--whiteColor);
}

.action-button.delete:hover {
    background-color: #ff3333;
}

.blog-post-link {
    display: inline-block;
    color: var(--PrimaryColor);
    text-decoration: none;
    font-weight: bold;
    transition: color var(--transitionDuration);
    font-size: 13px;
}

.blog-post-link:hover {
    color: var(--HoverColor);
}

@media (max-width: 768px) {
    .sidebar {
        flex-direction: column;
    }

    .blog-container {
        padding: 1rem;
    }

    .blog-table-wrapper {
        padding: 1rem;
    }

    .blog-table {
        font-size: 14px;
    }

    .action-button {
        width: 50px;
        height: 30px;
        font-size: 12px;
    }
}

