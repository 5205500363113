
:root{
    --PrimaryColor:hsl(26,93%,50%);
    --HoverColor:hsl(26,90%,42%);
    --whiteColor:hsl(0,0%,100%);
    --blackColor:hsl(0,0%,18%);
    --textColor: hsl(240,1%,48%);
    --whiteColorDeam:hsl(240,1%,48%);
    --greyBg:hsl(0,0%,96%);
    --greyText:rgb(190,190,190);
    --inputColor: hsl(330,12%,97%);
     --boxShadow: box-shadow: 0 2px 8px 2px rgba(178,178,178,0.334);
}
.verify-email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    background-color: var(--blackColor)
  }
  
  .verify-email-container p {
    font-size: 18px;
    color: var(--whiteColor);
    margin-top: 20px;
  }
  