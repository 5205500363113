:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --whiteColorDeam: hsl(240, 1%, 48%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
}

.dashboard {
    display: flex;
    gap: 20px;

    .dashboard-content {
        flex: 1;
        background: var(--greyBg);
        padding: 2rem;
        border-radius: 10px;
        display: flex;
        gap: 1.5rem;
        justify-content: space-between;
        margin-top: 3rem;
    }
}