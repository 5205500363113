:root {
  --PrimaryColor: hsl(26, 93%, 50%);
  --HoverColor: hsl(26, 90%, 42%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
}

/* contactUs.css */
.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--greyBg);
}

.content-container {
  flex: 1;
  padding: 2rem 1rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.title {
  font-size: 2.5rem;
  color: var(--blackColor);
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 600;
  margin-top: 5rem;
}

.form-map-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  background-color: var(--whiteColor);
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .form-map-container {
    grid-template-columns: 1fr 1fr;
  }
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: var(--textColor);
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  padding: 0.75rem;
  border: 1px solid var(--greyText);
  border-radius: 0.5rem;
  background-color: var(--inputColor);
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--PrimaryColor);
}

.form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.submit-button {
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.submit-button:hover {
  background-color: var(--HoverColor);
}

.map-container {
  height: 400px;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid var(--greyText);
}

/* Add smooth transitions */
input,
textarea,
button {
  transition: all 0.3s ease;
}

/* Add focus styles */
input:focus,
textarea:focus {
  box-shadow: 0 0 0 2px rgba(var(--PrimaryColor), 0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .content-container {
    padding: 1rem;
  }
  
  .title {
    font-size: 2rem;
  }
  
  .form-map-container {
    padding: 1rem;
  }
}