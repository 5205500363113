:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --whiteColorDeam: hsl(240, 1%, 48%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
}

.blog {
    padding: 2rem 0;
    background-color: var(--whiteColor);

    .secIntro {
        text-align: center;
        margin-bottom: 2rem;

        .secTitle {
            font-size: 2rem;
            color: var(--blackColor);
            margin-bottom: 0.5rem;
        }

        p {
            font-size: 1rem;
            color: var(--textColor);
        }
    }

    .mainContainer {
        display: grid;
        gap: 1.5rem;

        .singlePost {
            display: grid;
            gap: 1rem;
            background-color: var(--whiteColor);
            border-radius: 1rem;
            padding: 1rem;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

            .imgDiv {
                height: 200px;
                width: 100%;
                border-radius: 1rem;
                border: 5px solid var(--greyBg);
                box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.406);
                overflow: hidden;

                &:hover img {
                    transform: scale(1.1);
                    transition: 0.5s ease-in-out;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .postDetail {
                h3 {
                    color: var(--blackColor);
                    font-weight: 700;
                    font-size: 1.2rem;
                    line-height: 1.5rem;
                    margin-bottom: 0.5rem;
                }

                p {
                    font-size: 0.9rem;
                    color: var(--textColor);
                    font-weight: 500;
                    line-height: 1.4;
                }
            }

            a {
                font-size: 0.9rem;
                color: var(--PrimaryColor);
                font-weight: 600;
                display: inline-flex;
                align-items: center;
                gap: 0.5rem;
                text-decoration: none;

                &:hover {
                    color: var(--HoverColor);

                    .icon {
                        transform: translateX(5px);
                        transition: transform 0.3s ease-in-out;
                    }
                }
            }
        }
    }
}
.imgDiv img{
    width: 100%;
    height: 100%;
}
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    .paginationBtn {
        background-color: var(--PrimaryColor);
        color: var(--whiteColor);
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 5px;
        margin: 0 0.5rem;
        cursor: pointer;
        font-size: 1rem;

        &:disabled {
            background-color: var(--greyBg);
            color: var(--greyText);
            cursor: not-allowed;
        }
    }

    .paginationInfo {
        font-size: 0.9rem;
        color: var(--textColor);
        margin: 0 1rem;
    }
}

@media screen and (min-width: 884px) {
    .blog {
        .mainContainer {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media screen and (min-width: 1200px) {
    .blog {
        .mainContainer {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
