:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --whiteColorDeam: hsl(240, 1%, 48%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
}

.blog-details-container {
    /* max-width: 1000px; */
    margin: 0 auto;
    padding: 2rem 1rem;
    background-color: var(--whiteColor);
    color: var(--blackColor);
padding-top:10rem;
}

.blog-details-header {
    margin-bottom: 2rem;
}

.back-link {
    display: flex;
    align-items: center;
    color: var(--PrimaryColor);
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;
}

.back-link:hover {
    color: var(--HoverColor);
}

.back-link .icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
}

.blog-title-section {
    text-align: center;
    margin-bottom: 2rem;
}

.blog-title {
    font-size: 2.5rem;
    color: var(--PrimaryColor);
    margin-bottom: 0.5rem;
}

.blog-subtitle {
    font-size: 1.5rem;
    color: var(--textColor);
    font-weight: 400;
}

.blog-image-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 2rem;
}

.blog-gallery-image {
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
}

.blog-gallery-image:hover {
    transform: scale(1.05);
}

.blog-gallery-image img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.blog-text-content {
    background-color: var(--greyBg);
    padding: 2rem;
    border-radius: 10px;
}

.blog-description p {
    margin-bottom: 1.5rem;
    line-height: 1.7;
    color: var(--textColor);
}

.blog-highlights {
    margin-top: 2rem;
    background-color: var(--whiteColor);
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.05);
}

.blog-highlights h3 {
    color: var(--PrimaryColor);
    margin-bottom: 1rem;
    border-bottom: 2px solid var(--PrimaryColor);
    padding-bottom: 0.5rem;
}

.blog-highlights ul {
    list-style-type: none;
    padding: 0;
}

.blog-highlights li {
    position: relative;
    padding-left: 2rem;
    margin-bottom: 0.75rem;
    color: var(--textColor);
}

.blog-highlights li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: var(--PrimaryColor);
    font-weight: bold;
}

@media (max-width: 768px) {
    .blog-image-gallery {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .blog-image-gallery {
        grid-template-columns: 1fr;
    }
}