:root {
    --primary-color: hsl(26, 93%, 50%);
    --hover-color: hsl(26, 90%, 42%);
    --white-color: #ffffff;
    --black-color: #2e2e2e;
    --text-color: #666666;
    --grey-bg: #f5f5f5;
    --grey-text: #999999;
    --border-color: #e0e0e0;
    --error-color: #dc3545;
    --shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    --transition: 0.3s ease;
  }
  
  /* Layout Styles */
  .dashboard-layout {
    display: flex;
    min-height: 100vh;
    background-color: var(--grey-bg);
  }
  
  .main-content {
    flex: 1;
    overflow-y: auto;
  }
  
  .content-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }
  
  .main-title {
    color: var(--primary-color);
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  /* Button Styles */
  .btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    transition: var(--transition);
  }
  
  .btn-primary {
    background-color: var(--primary-color);
    color: var(--white-color);
  }
  
  .btn-primary:hover {
    background-color: var(--hover-color);
  }
  
  .btn-outline {
    background-color: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
  
  .btn-outline:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
  }
  
  .btn-danger {
    background-color: var(--error-color);
    color: var(--white-color);
  }
  
  .btn-danger:hover {
    background-color: #c82333;
  }
  
  /* Limit Controls */
  .limit-controls {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .limit-card {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: var(--white-color);
    border-radius: 0.375rem;
    box-shadow: var(--shadow);
  }
  
  /* Modal Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: var(--white-color);
    padding: 2rem;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 400px;
    box-shadow: var(--shadow);
  }
  
  .modal-title {
    color: var(--black-color);
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  /* Form Styles */
  .limit-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .form-group label {
    color: var(--text-color);
    font-weight: 500;
  }
  
  .form-input {
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 0.375rem;
    font-size: 1rem;
  }
  
  .form-input:focus {
    outline: none;
    border-color: var(--primary-color);
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
  
  /* Card Styles */
  .card {
    background-color: var(--white-color);
    border-radius: 0.5rem;
    box-shadow: var(--shadow);
    overflow: hidden;
  }
  
  .card-header {
    padding: 1.5rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .card-title {
    color: var(--black-color);
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }
  
  .card-content {
    padding: 1.5rem;
  }
  
  /* Table Styles */
  .table-container {
    overflow-x: auto;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .data-table th,
  .data-table td {
    padding: 0.75rem 1rem;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
  }
  
  .data-table th {
    background-color: var(--grey-bg);
    font-weight: 600;
    color: var(--black-color);
  }
  
  .data-table tr:hover {
    background-color: var(--grey-bg);
  }
  
  /* Utility Classes */
  .error-message {
    color: var(--error-color);
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .empty-message {
    text-align: center;
    color: var(--text-color);
    padding: 2rem;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
  
  .loading-spinner {
    animation: spin 1s linear infinite;
    width: 2rem;
    height: 2rem;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .dashboard-layout {
      flex-direction: column;
    }
  
    .content-container {
      padding: 1rem;
    }
  
    .limit-controls {
      flex-direction: column;
    }
  
    .limit-card {
      width: 100%;
    }
  
    .modal-content {
      margin: 1rem;
      max-width: none;
    }
  
    .data-table {
      font-size: 0.875rem;
    }
  }