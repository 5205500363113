/* LoginForm.css */
:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --whiteColorDeam: hsl(240, 1%, 48%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
    --boxShadow: 0 2px 8px 2px rgba(178, 178, 178, 0.334);
}

.containerLogin {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-top: 5rem;
    background: var(--blackColor);
}

.containerLogin .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}

.text {
    color: var(--PrimaryColor);
    font-size: 30px;
    font-weight: 600;
    line-height: 1.5rem;
}

.underline {
    width: 78px;
    height: 6px;
    background: var(--textColor);
    border-radius: 1rem;
}

.inputs {
    display: flex;
    flex-direction: column;
    gap: 9px;
    margin-top: 55px;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
}

.input {
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--whiteColor);
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    max-width: 480px;
    height: 80px;
    border-radius: 1rem;
}

.input .icon {
    font-size: 25px;
    color: var(--textColor);
    margin: 0px 30px;
}

.input input {
    border: none;
    outline: none;
    font-size: 20px;
    color: var(--textColor);
    width: 100%;
}

.input input::placeholder {
    font-size: 14px;
}

.forget-password {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--textColor);
    font-size: 14px;
    font-weight: 500;
    padding-top: 20px;
}

.forget-password span {
    color: var(--PrimaryColor);
    cursor: pointer;
    padding-left: 5px;
}

.submit-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 40px;
}

.submit {
    background: none;
    color: var(--whiteColor);
    font-size: 18px;
    font-weight: 500;
    padding: 10px 50px;
    border-radius: 1rem;
    border: .1rem solid var(--PrimaryColor);
    cursor: pointer;
}

.submit:hover {
    background: var(--HoverColor);
}

.submit button {
    align-items: center;
    background: none;
    color: var(--whiteColor);
}

.gray {
    /* background: var(--PrimaryColor); */
    color: var(--PrimaryColor);
}

.gray:hover {
    background: var(--blackColor);
    color: var(--whiteColor);
}

.message {
    color: var(--whiteColor);
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
}

@media screen and (max-width: 640px) {
    .submit-container {
        gap: 10px;
        padding-top: 10px;
        padding-bottom: 20px;
    }

    .inputs .input {
        height: 65px;
    }
}

@media screen and (max-width: 480px) {
    .submit-container {
        display: flex;
        gap: 10px;
        padding-top: 10px;
        padding-bottom: 20px;
    }

    .inputs .input {
        height: 60px;
    }

    .submit-container .submit {
        padding: 5px 20px;
    }
}

@media screen and (max-width: 340px) {
    .submit-container {
        display: flex;
        flex-direction: column;
    }
}