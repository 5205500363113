:root {
  --PrimaryColor: hsl(26, 93%, 55%);

  --HoverColor: hsl(26, 90%, 45%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 20%);
  --textColor: hsl(240, 1%, 20%);
  --lightTextColor: hsl(240, 1%, 40%);
  --whiteColorDeam: hsl(0, 0%, 95%);
  --greyBg: hsl(0, 0%, 98%);
  --greyText: rgb(100, 100, 100);
  --inputColor: hsl(210, 15%, 95%);
}

body {
  font-family: 'Arial', sans-serif;
  color: var(--textColor);
  background-color: var(--greyBg);
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.tour-page {
  color: var(--textColor);
  background-color: var(--whiteColor);

}

.tourism-booking {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 9rem;
}

.main-content {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.left-content {
  flex: 2;
}

.destination-title {
  color: var(--PrimaryColor);
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.destination-description {
  margin-bottom: 2rem;
  line-height: 1.8;
}

.tour-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.info-item .icon {
  color: var(--PrimaryColor);
}



.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.gallery-image {
  width: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.gallery-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-image:hover {
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .image-gallery {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}






.booking-section {
  flex: 1;

  background-color: var(--whiteColor);
  padding: 1rem;

}

.booking-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

}

.form-title {
  color: var(--PrimaryColor);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-input {
  padding: 0.75rem;
  border: 1px solid var(--greyText);
  border-radius: 4px;
  font-size: 1rem;
  background-color: var(--inputColor);
  transition: border-color 0.3s ease;
}

.form-input:focus {
  border-color: var(--PrimaryColor);
  outline: none;
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.form-checkbox {
  width: 1.2rem;
  height: 1.2rem;
}

.total {
  font-size: 1.2rem;
  margin-top: 1rem;
}

.book-now {
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
  border: none;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.book-now:hover:not(:disabled) {
  background-color: var(--HoverColor);
  transform: translateY(-2px);
}

.book-now:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-bottom: 1rem;
}

.loading,
.error {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }

  .booking-section {
    margin-top: 2rem;
  }

  .destination-title {
    font-size: 2rem;
  }

  .form-title {
    font-size: 1.3rem;
  }
}