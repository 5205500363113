:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
    --transitionDuration: 0.3s;
    --boxShadow: 2px 0 10px rgba(0, 0, 0, 0.1);
    --borderRadius: 0.5rem;
}

.menu {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100vh; /* Full height */
    padding: 1.5rem;
    background-color: var(--whiteColor);
    position: fixed; /* Keep the sidebar fixed */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    width: 260px; /* Set width */
    transform: translateX(-100%); /* Initially hidden */
    transition: transform var(--transitionDuration) ease-in-out;
    z-index: 1000; /* Maintain a high z-index */
    box-shadow: var(--boxShadow);
    border-right: 1px solid var(--greyBg);
    overflow-y: auto; /* Allow scrolling */
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
.menu::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
}

/* Hide scrollbar for Firefox */
.menu {
    scrollbar-width: none; /* Hide scrollbar */
}

.menu.open {
    transform: translateX(0);
}

.logo {
    text-align: center;
    padding: 1rem 0;
    color: var(--PrimaryColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.logo-img {
    width: 90px;
    height: auto;
}

.menu-list {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.item {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.8rem 1rem;
    border-radius: var(--borderRadius);
    transition: background-color var(--transitionDuration), color var(--transitionDuration);
    cursor: pointer;
    color: var(--textColor);
    position: relative;
}

.item:hover {
    background-color: var(--greyBg);
}

.item.active {
    background-color: var(--PrimaryColor);
    color: var(--whiteColor);
}

.item .icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
    color: var(--PrimaryColor);
}

.dropdown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dropdown-icon {
    font-size: 1.2rem;
    transition: transform var(--transitionDuration);
}

.dropdown-icon.open {
    transform: rotate(180deg);
}

.dropdown-content {
    display: none;
    flex-direction: column;
    margin-top: 0.5rem;
    border-radius: var(--borderRadius);
    overflow: hidden;
    padding-left: 2rem;
    font-size: 0.9rem;
}

.dropdown-content a {
    padding: 0.6rem 1rem;
    margin: 0.2rem 0;
    text-decoration: none;
    color: var(--textColor);
    border-radius: var(--borderRadius);
    transition: background-color var(--transitionDuration);
}

.dropdown-content a:hover {
    background-color: var(--HoverColor);
    color: var(--whiteColor);
}

.item.open .dropdown-content {
    display: flex;
}

.menu-icon {
    position: fixed;
    top: 1.5rem;
    left: 1.5rem;
    z-index: 1500;
    cursor: pointer;
}

.toggle-icon {
    font-size: 2rem;
    color: var(--PrimaryColor);
    transition: transform var(--transitionDuration);
}

.menu-icon:hover .toggle-icon {
    transform: rotate(90deg);
}

.logout-button {
    margin-top: auto;
    padding: 1.5rem;
    text-align: center;
    background-color: var(--greyBg);
    border-radius: var(--borderRadius);
}

.logout-btn {
    background-color: #ff4b5c;
    color: var(--whiteColor);
    border: none;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    border-radius: var(--borderRadius);
    font-size: 1rem;
    transition: background-color var(--transitionDuration);
}

.logout-btn:hover {
    background-color: #ff1e3c;
}

@media (min-width: 930px) {
    .menu {
        transform: translateX(0); /* Always visible on larger screens */
        width: 260px;
        position: relative; /* Change to relative for larger screens */
    }
    .menu-icon {
        display: none; /* Hide menu icon on larger screens */
    }
}