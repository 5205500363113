:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
    --transitionDuration: 0.3s;
    --boxShadow: 2px 0 10px rgba(0, 0, 0, 0.1);
    --borderRadius: 0.5rem;
}

.sidebar {
    display: flex;
    height: 100vh;
}

.booking-container {
    flex: 1;
    padding: 2rem;
    overflow-y: auto;
}

.booking-title {
    color: var(--PrimaryColor);
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background-color: var(--whiteColor);
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
    padding: 2rem;
    width: 90%;
    max-width: 500px;
    max-height: 80vh;
    overflow-y: auto;
}

.booking-form {
    display: grid;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-label {
    color: var(--textColor);
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.form-input {
    padding: 0.75rem;
    border: 1px solid var(--greyText);
    border-radius: var(--borderRadius);
    background-color: var(--inputColor);
    transition: border-color var(--transitionDuration);
}

.form-input:focus {
    border-color: var(--PrimaryColor);
    outline: none;
}

.submit-button, .cancel-button {
    padding: 0.75rem 1rem;
    border: none;
    border-radius: var(--borderRadius);
    cursor: pointer;
    transition: background-color var(--transitionDuration);
    font-weight: bold;
}

.submit-button {
    background-color: var(--PrimaryColor);
    color: var(--whiteColor);
}

.submit-button:hover {
    background-color: var(--HoverColor);
}

.cancel-button {
    background-color: var(--greyBg);
    color: var(--textColor);
    margin-right: 1rem;
}

.cancel-button:hover {
    background-color: var(--greyText);
}

.booking-table-wrapper {
    background-color: var(--whiteColor);
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
    padding: 2rem;
    overflow-x: auto;
    margin-top: 2rem;
}

.booking-table-title {
    color: var(--PrimaryColor);
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.booking-table {
    width: 100%;
    border-collapse: collapse;
}

.booking-table th,
.booking-table td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid var(--greyText);
}

.booking-table th {
    background-color: var(--greyBg);
    color: var(--blackColor);
}

.actions-btn-booking {
    display: flex;
    gap: 10px;
}

.action-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: var(--borderRadius);
    cursor: pointer;
    font-weight: bold;
    transition: background-color var(--transitionDuration);
}

.action-button.edit {
    background-color: var(--PrimaryColor);
    color: var(--whiteColor);
}

.action-button.edit:hover {
    background-color: var(--HoverColor);
}

.action-button.delete {
    background-color: #ff4d4d;
    color: var(--whiteColor);
}

.action-button.delete:hover {
    background-color: #ff3333;
}

.add-booking-button {
    background-color: var(--PrimaryColor);
    color: var(--whiteColor);
    padding: 0.75rem 1rem;
    border: none;
    border-radius: var(--borderRadius);
    cursor: pointer;
    transition: background-color var(--transitionDuration);
    font-weight: bold;
    margin-bottom: 1rem;
}

.add-booking-button:hover {
    background-color: var(--HoverColor);
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.error-message {
    color: #ff4d4d;
    margin-bottom: 1rem;
    text-align: center;
}

@media (max-width: 768px) {
    .sidebar {
        flex-direction: column;
    }

    .booking-container {
        padding: 1rem;
    }

    .popup-content {
        width: 95%;
        padding: 1rem;
    }

    .booking-table-wrapper {
        padding: 1rem;
    }
}

