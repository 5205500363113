:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
  }
  
  .partners-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--greyBg);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;
    color: var(--PrimaryColor);
  }
  
  .partners-list {
    display: flex;
    overflow: hidden;
    position: relative;
  }
  
  .partner-card {
    background-color: var(--whiteColor);
    border: 1px solid var(--greyText);
    border-radius: 8px;
    padding: 20px;
    width: 200px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    animation: moveLeftToRight 30s linear infinite;
  }
  
  .partner-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    background-color: var(--HoverColor);
  }
  
  .partner-logo {
    max-width: 150px;
    max-height: 80px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  
  .partner-name {
    font-size: 16px;
    color: var(--textColor);
    text-align: center;
    margin: 0;
  }
  
  @keyframes moveLeftToRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  /* Add more partner cards to create a seamless loop */
  .partners-list::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 100%;
    animation: moveLeftToRight 30s linear infinite;
    animation-delay: 15s;
  }
  
  .partners-list:hover .partner-card,
  .partners-list:hover::after {
    animation-play-state: paused;
  }
  
  