:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --whiteColorDeam: hsl(240, 1%, 48%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
  }
  
  .home {
    min-height: 100vh;
    background: linear-gradient(rgba(33, 33, 33, 0.522), rgba(33, 33, 33, 0.522)), url("../../assets/Conventionr.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
  }
  
  .secContainer {
    width: 100%;
    max-width: 1200px;
  }
  
  .homeText {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .title {
    color: var(--whiteColor);
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 1rem;
  }
  
  .subTitle {
    color: var(--whiteColor);
    font-size: 1rem;
    font-weight: 300;
    opacity: 0.9;
    max-width: 70%;
    margin: 0 auto;
  }
  
  .btn {
    padding: 0.6rem 1.5rem;
    background-color: var(--PrimaryColor);
    color: var(--whiteColor);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    min-height: 44px;
    min-width: 44px;
  }
  
  .btn:hover {
    background-color: var(--HoverColor);
  }
  
  .homeCard {
    background: var(--whiteColor);
    padding: 1.5rem;
    border-radius: 1rem;
    box-shadow: 0 2px 8px 4px rgba(178, 178, 178, 0.45);
  }
  
  .searchInputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .inputGroup {
    display: flex;
    flex-direction: column;
  }
  
  .inputGroup label {
    color: var(--textColor);
    font-weight: 500;
    margin-bottom: 0.4rem;
  }
  
  .inputGroup input {
    width: 100%;
    background: var(--inputColor);
    border-radius: 10px;
    border: none;
    padding: 10px;
    font-size: 13px;
    font-weight: 500;
  }
  
  .inputGroup input::placeholder {
    font-size: 13px;
    font-weight: 400;
    opacity: 0.5;
  }
  
  .inputGroup input:focus {
    outline: none;
  }
  
  .searchBtn {
    width: 100%;
  }
  
  /* Search popup styles */
  .search-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 90%;
    max-width: 400px;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .search-popup-content {
    position: relative;
  }
  
  .search-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  
  .close-popup {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 0 5px;
    min-height: 44px;
    min-width: 44px;
  }
  
  .search-feedback {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    font-weight: 500;
  }
  
  .search-feedback.success {
    background-color: #e7f5e7;
    color: #0a5d0a;
  }
  
  .search-feedback.error {
    background-color: #fee7e7;
    color: #b91c1c;
  }
  
  .search-history {
    max-height: 50vh;
    overflow-y: auto;
    margin-top: 15px;
  }
  
  .search-history h4 {
    margin-bottom: 10px;
    color: #666;
  }
  
  .search-item {
    padding: 10px;
    margin-bottom: 8px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .search-item.success {
    background-color: #f7f7f7;
  }
  
  .search-item.error {
    background-color: #fff5f5;
  }
  
  .search-text {
    font-weight: bold;
  }
  
  .search-time {
    font-size: 0.8em;
    color: #666;
  }
  
  .clear-history {
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    background: #ff5555;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    min-height: 44px;
    min-width: 44px;
  }
  
  .clear-history:hover {
    background: #ff3333;
  }
  
  /* Highlight style for search results */
  .highlight {
    background-color: #ffd700;
    padding: 0 2px;
    border-radius: 2px;
  }
  
  /* Responsive styles */
  @media screen and (min-width: 480px) {
    .title {
      font-size: 2.5rem;
    }
  
    .subTitle {
      font-size: 1.1rem;
    }
  }
  
  @media screen and (min-width: 768px) {
    .homeCard {
      padding: 2rem;
    }
  
    .searchInputs {
      flex-direction: row;
    }
  
    .inputGroup {
      flex: 1;
    }
  
    .searchBtn {
      align-self: flex-end;
    }
  }
  
  @media screen and (min-width: 1024px) {
    .title {
      font-size: 3.5rem;
    }
  
    .subTitle {
      font-size: 1.2rem;
    }
  
    .homeCard {
      display: flex;
      align-items: flex-end;
      gap: 1rem;
    }
  
    .searchInputs {
      flex: 1;
      margin-bottom: 0;
    }
  
    .searchBtn {
      width: auto;
    }
  }
  
  @media screen and (max-width: 480px) {
    .search-item {
      font-size: 14px;
    }
  
    .search-time {
      font-size: 12px;
    }
  }
  
  