:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
  }
  
  .offer-details {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background-color: var(--whiteColor);
    color: var(--textColor);
    font-family: 'Arial', sans-serif;
    padding-top: 10rem;
  }
  
  .offer-images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .room-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .room-image:hover {
    transform: scale(1.05);
  }
  
  .offer-content h1 {
    color: var(--PrimaryColor);
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .offer-description {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  
  .price-info {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .price {
    color: var(--PrimaryColor);
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .amenities h2 {
    color: var(--PrimaryColor);
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .amenities ul {
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 0.5rem;
  }
  
  .amenities li {
    padding-left: 1.5rem;
    position: relative;
  }
  
  .amenities li::before {
    content: '•';
    color: var(--PrimaryColor);
    position: absolute;
    left: 0;
    font-weight: bold;
  }
  
  .booking-form {
    margin-top: 2rem;
    background-color: var(--greyBg);
    padding: 1.5rem;
    border-radius: 8px;
  }
  
  .booking-form h2 {
    color: var(--PrimaryColor);
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .form-row {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .form-group {
    flex: 1;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--greyText);
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .submit-btn {
    background-color: var(--PrimaryColor);
    color: var(--whiteColor);
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
  }
  
  .submit-btn:hover {
    background-color: var(--HoverColor);
  }
  
  @media (min-width: 768px) {
    .offer-details {
      flex-direction: row;
      gap: 2rem;
    }
  
    .offer-images {
      flex: 1;
    }
  
    .offer-content {
      flex: 1;
    }
  }
  
  @media (max-width: 767px) {
    .offer-details {
      padding: 1rem;
    }
  
    .offer-content h1 {
      font-size: 2rem;
    }
  
    .booking-form {
      padding: 1rem;
    }
  
    .form-row {
      flex-direction: column;
    }
  }
  
  
  .base-price {
    margin-bottom: 1rem;
  }
  
  .base-note {
    font-size: 0.9rem;
    color: #666;
    margin-top: 0.5rem;
  }
  
  .price {
    font-size: 1.2rem;
    /* font-weight: bold; */
    color: #2c5282;
  }