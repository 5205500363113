/* Destination.css */
:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --whiteColorDeam: hsl(240, 1%, 48%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
    --boxShadow: 0 2px 8px 2px rgba(178, 178, 178, 0.334);
}

.destination {
    
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.destination-title {
    position: relative;
    text-align: center;
    color: var(--blackColor);
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 40px;
    padding-bottom: 15px;
    background: var(--PrimaryColor);
    color: var(--whiteColor);
    padding: 15px;
    border-radius: 10px;
}

.destination-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 4px;
    background: var(--HoverColor);
    border-radius: 2px;
}

.destination-container {
    display: flex;
    justify-content: center;
    background-color: var(--greyBg);
    border-radius: 12px;
    box-shadow: var(--boxShadow);
    overflow: hidden;
    padding-top: 5rem;
}

.destination-content {
    max-width: 800px;
    padding: 40px;
    line-height: 1.7;
    color: var(--textColor);
}

.destination-content h2 {
    color: var(--blackColor);
    border-bottom: 2px solid var(--PrimaryColor);
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 1.8rem;
    font-weight: 600;
}

.destination-content h3 {
    color: var(--blackColor);
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 1.4rem;
    font-weight: 500;
    position: relative;
    padding-left: 20px;
}

.destination-content h3::before {
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    width: 10px;
    height: 10px;
    background: var(--PrimaryColor);
    border-radius: 50%;
}

.destination-content p {
    margin-bottom: 20px;
    color: var(--textColor);
}

.destination-content strong {
    color: var(--blackColor);
    font-weight: 600;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .destination {
        padding: 20px 15px;
    }

    .destination-title {
        font-size: 2.2rem;
        padding: 10px;
    }

    .destination-container {
        border-radius: 8px;
    }

    .destination-content {
        padding: 25px;
    }
}