:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
    --transitionDuration: 0.3s;
    --boxShadow: 2px 0 10px rgba(0, 0, 0, 0.1);
    --borderRadius: 0.5rem;
}

.dest-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.dest-title {
    color: var(--PrimaryColor);
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background-color: var(--whiteColor);
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
    padding: 2rem;
    width: 90%;
    max-width: 500px;
    max-height: 80vh;
    overflow-y: auto;
}

.dest-form {
    display: grid;
    gap: 1rem;
    width: 100%;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-label {
    color: var(--textColor);
    margin-bottom: 0.5rem;
}

.form-input {
    padding: 0.5rem;
    border: 1px solid var(--greyText);
    border-radius: var(--borderRadius);
    background-color: var(--inputColor);
    transition: border-color var(--transitionDuration);
}

.form-input:focus {
    border-color: var(--PrimaryColor);
    outline: none;
}

.actions-btn {
    margin-top: 23px;
}

.form-input-file {
    padding: 0.5rem;
    border: 1px solid var(--greyText);
    border-radius: var(--borderRadius);
    background-color: var(--inputColor);
}

.uploaded-image {
    max-width: 100%;
    height: auto;
    margin-top: 1rem;
    border-radius: var(--borderRadius);
}

.submit-button,
.cancel-button {
    background-color: var(--PrimaryColor);
    color: var(--whiteColor);
    padding: 0.75rem 1rem;
    border: none;
    border-radius: var(--borderRadius);
    cursor: pointer;
    transition: background-color var(--transitionDuration);
    margin-right: 0.5rem;
}

.submit-button:hover,
.cancel-button:hover {
    background-color: var(--HoverColor);
}

.cancel-button {
    background-color: var(--greyBg);
    color: var(--textColor);
}

.cancel-button:hover {
    background-color: var(--greyText);
}

.dest-table-wrapper {
    background-color: var(--whiteColor);
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
    padding: 2rem;
    overflow-x: auto;
    /* margin-top: 2rem; */
    padding: 20px 150px;
}

.dest-table-title {
    color: var(--PrimaryColor);
    font-size: 1.5rem;
    margin-bottom: 1rem;

}

.dest-table {
    width: 100%;
    border-collapse: collapse;
}

.dest-table th,
.dest-table td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid var(--greyText);
}

.dest-table th {
    background-color: var(--greyBg);
    color: var(--blackColor);
}

.dest-table-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: var(--borderRadius);
}

.add-destination-button {
    background-color: var(--PrimaryColor);
    color: var(--whiteColor);
    padding: 0.75rem 1rem;
    border: none;
    border-radius: var(--borderRadius);
    cursor: pointer;
    transition: background-color var(--transitionDuration);
    margin-bottom: 1rem;
}

.add-destination-button:hover {
    background-color: var(--HoverColor);
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.no-destinations {
    text-align: center;
    color: var(--textColor);
    margin-top: 2rem;
}